import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

import homeImg from "../images/home.jpg";
import jigyo1Img from "../images/jigyo1.jpg";
import jigyo2Img from "../images/jigyo2.jpg";
import jigyo3Img from "../images/jigyo3.jpg";

const IndexPage = ({data}) => (
  <>
  <Layout>
    <SEO title="Home" />

    <Row style={{maxWidth: '960px', margin: '0 auto'}}>
      <img src={homeImg} style={{width: '100%', marginBottom: '2rem'}} />
    </Row>

    <Row style={{justifyContent: 'center', padding: '0 15px'}}>
      <Col xs={12} md={6} style={{maxWidth: '480px'}}>
        <Row>
          <Col style={{backgroundColor: 'cornflowerblue', color: 'white', padding: '0.5rem'}}>インフォメーション</Col>
        </Row>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Row>
            <Col style={{padding: '0.5rem'}}>
            <Link to={`${node.fields.slug}`}>
              {node.frontmatter.data}記事{`   `}{node.frontmatter.title}
            </Link>
            </Col>
          </Row>
        ))}
      </Col>
      <Col xs={12} md={6} style={{maxWidth: '480px'}}>
        <Row>
          <Col style={{backgroundColor: 'cornflowerblue', color: 'white', padding: '0.5rem'}}>情報</Col>
        </Row>
        <Row>
          <Col style={{padding: '0.5rem'}}>情報1</Col>
        </Row>
        <Row>
          <Col style={{padding: '0.5rem'}}>情報2</Col>
        </Row>
        <Row>
          <Col style={{padding: '0.5rem'}}>情報3</Col>
        </Row>
        <Row>
          <Col style={{padding: '0.5rem'}}>情報4</Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col style={{backgroundColor: 'cornflowerblue', color: 'white', padding:'0.5rem', maxWidth: '960px', margin: '2rem auto 0'}}>事業内容</Col>
    </Row>

    <Row>
      <Col style={{maxWidth: '960px', margin: '0 auto'}}>
        <Row>
          <Col xs={12} md={4}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyo1Img} />
              <Card.Body>
                <Card.Title style={{fontSize: '1rem', marginBottom: '0'}}>画像1</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyo2Img} />
              <Card.Body>
                <Card.Title style={{fontSize: '1rem', marginBottom: '0'}}>画像2</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card style={{marginTop: '1rem'}}>
              <Card.Img variant="top" src={jigyo3Img} />
              <Card.Body>
                <Card.Title style={{fontSize: '1rem', marginBottom: '0'}}>画像3</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col style={{maxWidth: '960px', paddingTop: '3rem'}}></Col>
    </Row>
  </Layout>
  </>
);

export default IndexPage;

export const query = graphql`
query {
  allMarkdownRemark(
    limit: 4,
    sort: {
      fields: frontmatter___date,
      order: DESC
    }
  )
  {
    edges {
      node {
        frontmatter {
          title
          date(formatString: "YYYY年MM月DD日")
        }
        fields {
          slug
        }
      }
    }
  }
}
`